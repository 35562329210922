export interface Filter {
  id: number
  name: string
  description: string
  featuredImage: string
  type: number
  parentId: number|null
  friendlyUrl: string
}

export enum eFilterType {
  Unknown = 0,
  Category,
  Subcategory
}

export interface FilterState {
  filters: Filter[]
}
