import { VuexModule, Module, MutationAction, getModule, Action, Mutation } from 'vuex-module-decorators'
import store from '@/store'
import { eFilterType, Filter } from './types'
import axios from 'axios'
import { Product } from '../../../models/Product'

@Module({ namespaced: true, name: 'filters', store, dynamic: true })
class FilterModule extends VuexModule {
    filters: Filter[] = []
    selectedCategoryId: number | undefined = undefined
    selectedSubCategoryId: number | undefined = undefined
    paginationResult: { products: Product[], total: number } = { products: [], total: 0 };

    get categories() {
        return this.filters.filter(f => f.type == eFilterType.Category);
    }

    get selectedCategory() {
        return this.filters.find(f => f.id == this.selectedCategoryId);
    }

    get selectedCategorySubcategories() {
        return this.filters.filter(f => f.parentId == this.selectedCategoryId);
    }

    @MutationAction
    async fetchFilters() {
        const response = await axios.get('/api/filters')
        return { filters: response.data }
    }

    @Mutation
    saveSelectedCategoryId(categoryName: string) {
        const categoryId = this.filters.find(x => x.friendlyUrl === categoryName)?.id
        if (categoryId != this.selectedCategoryId) {
            this.selectedCategoryId = categoryId;
            //Reset subCategoryId when categoryId is changed
            this.selectedSubCategoryId = undefined;
        }
    }

    @Mutation
    saveSelectedSubcategoryId(subCategoryName: string) {
        const subCategoryId = this.filters.find(x => x.friendlyUrl === subCategoryName)?.id;
        if (subCategoryId != this.selectedSubCategoryId) {
            this.selectedSubCategoryId = subCategoryId;
        }
    }

    @Action({ commit: 'saveSelectedCategoryId' })
    actionSaveSelectedCategoryId(categoryName: string) {
        return categoryName
    }

    @Action({ commit: 'saveSelectedSubcategoryId' })
    actionSaveSelectedSubcategoryId(subCategoryName: string) {
        return subCategoryName
    }

    @Mutation
    saveProducts(result: { data: Product[], total: number }) {
        this.paginationResult.products = [];
        this.paginationResult.products = result.data;
        this.paginationResult.total = result.total;
    }
}

export default getModule(FilterModule);