import filter from "@/store/modules/filter";
import { eFilterType, Filter } from "@/store/modules/filter/types";
import { Vue, Component } from "vue-property-decorator";
import { RawLocation } from "vue-router";

interface LeftNav extends Filter {
    route?: { name: string, params?: any },
}

@Component
export class FilterMixin extends Vue {
    get leftNavigations() {
        return filter.filters.map(c => {
            switch (c.type) {
                case eFilterType.Category:
                    return { ...c, route: { name: 'Category', params: { categorySlug: c.friendlyUrl } } }

                case eFilterType.Subcategory: {
                    const parentCat = filter.filters.find(f => f.id == c.parentId);
                    if (parentCat)
                        return { ...c, route: { name: 'SubCategory', params: { categorySlug: parentCat.friendlyUrl, subCategorySlug: c.friendlyUrl } } };
                    return undefined;
                }

                default:
                    break;
            }
        })
    }

    get selectedCategory() {
        return filter.selectedCategory;
    }

    onFilterSelected(filterId: number) {
        const selectedFilter = this.leftNavigations.find(f => f?.id == filterId);
        if (selectedFilter)
            this.$router.push(selectedFilter.route as RawLocation);
    }

    fuzzyQuerySearch(str: any): any {

        if (str.indexOf("-") > -1) {
            return '"' + this.escapingCharacters(str) + '"';
        }
        else return this.escapingCharacters(str.replace(" ", "~ ") + "~");
    }

    escapingCharacters(str: any): any {
        return [].map.call(str, function escapeSpecialCharacter(char) {
            if (char === '+'
                || char === '&'
                || char === '|'
                || char === '!'
                || char === '('
                || char === ')'
                || char === '{'
                || char === '}'
                || char === '['
                || char === ']'
                || char === '^'
                || char === '"'
                || char === '*'
                || char === '?'
                || char === ':'
                || char === '\\'
                || char === '/'
            ) return '\\' + char
            else return char
        }).join('')
    }

}